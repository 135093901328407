<template>
  <div>
    <br /><br /><br /><br /><br /><br /><br /><br />
    <v-card class="mx-auto" max-width="500" outlined>
      <br />
      <h3 style="text-align: center">Reset your password</h3>
      <v-text-field
        v-model="resetPassword"
        prepend-icon="lock"
        placeholder="Enter Password"
        name="password"
        label="Enter password"
        :type="value ? 'password' : 'text'"
      />

      <v-text-field
        v-model="resetconfirmPassword"
        prepend-icon="lock"
        placeholder="Retype Password"
        name="password"
        label="Retype password"
        :type="value ? 'password' : 'text'"
      />
      <v-card-actions style="direction: rtl">
        <v-btn outlined rounded text @click="newpasswordset()"> Submit </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { required, digits, max, regex } from 'vee-validate/dist/rules';
// import EventBus from '../../utils/EventBus';
import { extend, setInteractionMode } from 'vee-validate';
import msgHelper from '../../utils/msg-helper';
import { mapState } from 'vuex';
setInteractionMode('eager');
extend('digits', {
  ...digits,
  message: '{_field_} needs to be {length} digits. ({_value_})',
});

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters',
});

extend('regex', {
  ...regex,
  message: '{_field_} {_value_} does not match {regex}',
});
export default {
  components: {},
  data: () => {
    return {
      resetconfirmPassword: '',
      resetPassword: '',
      loading: false,
      value: String,
    };
  },
  computed: {
    ...mapState(['packageId']),
  },
  mounted() {
    document.title = 'TuroBuddy | Customer Login or SignUp';
  },
  methods: {
    // Login using email and password
    newpasswordset() {
      if (this.resetPassword === '') {
        return msgHelper.showToast('warning', 'Please enter valid password');
      } else if (this.resetconfirmPassword === '') {
        return msgHelper.showToast(
          'warning',
          'Please enter valid retype password'
        );
      } else if (this.resetPassword !== this.resetconfirmPassword) {
        return msgHelper.showToast('warning', 'password must be same');
      } else {
        // this.loading = true;
        console.log('hhhhhhhhh', this.$router);
        this.$http
          .post('reset-password', {
            passwordResetToken: this.$router.currentRoute.query?.token,
            newPassword: this.resetconfirmPassword,
          })
          .then((res) => {
            
            this.$router.push({ name: 'Dashboard' });
            msgHelper.showToast('success', res.response.data.message);
          })
          .catch((err) => {
            console.log(err);
            if (err.response) {
              msgHelper.showToast('error', err.response.data.message);
            } else {
              msgHelper.showToast(
                'error',
                'Something went wrong. Please try again later'
              );
            }
            // this.loading = false;
          });
      }
    },
  },
};
</script>
